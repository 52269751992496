// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebase/firestore'; // if you're using Firestore
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAnHnB4eG8MxeXzPtGU0LL1Opa3Zin_Ncg",
  authDomain: "rinkclic.firebaseapp.com",
  projectId: "rinkclic",
  storageBucket: "rinkclic.appspot.com",
  messagingSenderId: "645538696266",
  appId: "1:645538696266:web:d8541c3589d432d1532ab3",
  measurementId: "G-XTGTJQECQ7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;