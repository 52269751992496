import React, { useEffect, useState } from 'react';
import app from './firebaseConfig'; // import your firebase configuration
import RinkMap from './RinkMap';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';

function App() {
  const [rinks, setRinks] = useState([]);
  const apiKey = 'AIzaSyCY7tnMWTt69m0AsmBNjpcJnwoMFylPJxI';


  const analytics = getAnalytics(app);

  useEffect(() => {
    // Function to fetch data from Firestore
    const fetchData = async () => {
      const db = getFirestore(app);
      const docRef = doc(db, "aggregatedrinks", "data");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setRinks(docSnap.data().patinoire);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      <RinkMap rinks={rinks} apiKey={apiKey} />
    </div>
  );
}

export default App;
