import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { map } from 'leaflet';

const mapContainerStyle = {
  height: '100vh',
  width: '100%'
};

const center = {
  lat: 45.5017,
  lng: -73.5673
};

const RinkMap = ({ rinks, apiKey }) => {
  const [selectedRink, setSelectedRink] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [mapZoom, setMapZoom] = useState(12);
  const [mapCenter, setMapCenter] = useState({
    lat: 45.5017,
    lng: -73.5673,
  });

  const onLoad = useCallback(() => {
    //console.log("Map Loaded");
    setIsMapLoaded(true);
  }, []);

  const onMapLoad = useCallback((map) => {
    map.addListener('zoom_changed', () => {
      setMapZoom(map.getZoom());
    });
    setIsMapLoaded(true);
  }, []);


  const adjustMarkerPositions = (markers) => {
    const adjustedMarkers = markers.map((marker, index, arr) => {
      let count = 1;
      arr.forEach((m, i) => {
        if (i !== index && m.location.lat === marker.location.lat && m.location.lng === marker.location.lng) {
          count++;
        }
      });
  
      if (count > 1) {
        const offset = 0.00025 * (index % count); // Adjust this value as needed
        return {
          ...marker,
          location: {
            lat: marker.location.lat - offset,
            lng: marker.location.lng + offset
          }
        };
      }
  
      return marker;
    });
  
    return adjustedMarkers;
  };

  const calculateAdjustedPosition = (originalPosition) => {
    // Adjust the offset value based on your requirements
    const offset = 0.001 * Math.pow(2, 15 - mapZoom); // Example offset calculation
    return {
      lat: originalPosition.lat + offset,
      lng: originalPosition.lng,
    };
  };

  const getMarkerIcon = (ouvert) => {
    if (!window.google || !window.google.maps) {
        console.log('getMarkerIcon: window.google or window.google.maps is not available');
      return null;
    }

    return {
      url: ouvert == 1 ? 'https://maps.google.com/mapfiles/ms/icons/green-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
      scaledSize: new window.google.maps.Size(30, 30),
      zIndex: 1000
    };
  };

  useEffect(() => {
    if (isMapLoaded) {
      const adjustedMarkers = adjustMarkerPositions(rinks);
      setMarkers(adjustedMarkers);
    }
  }, [isMapLoaded, rinks]);

  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        zoom={mapZoom}
        options={{ clickableIcons: false }}
        onLoad={onMapLoad}
      >
        {isMapLoaded && markers.map(rink => {
            //console.log('Rendering marker for:', rink.nom, rink.location);
            const markerIcon = getMarkerIcon(rink.ouvert);
            return (
                <Marker
                    key={rink.nom}
                    position={rink.location}
                    onClick={() => {
                      setMapCenter(rink.location);
                      setSelectedRink(rink);
                    }}
                    icon={markerIcon}
                />
            );
        })}

        {selectedRink && (
          <InfoWindow
            position={calculateAdjustedPosition(selectedRink.location)}
            onCloseClick={() => setSelectedRink(null)}
          >
            <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#333' }}>
              <h2 style={{ fontSize: '16px', marginBottom: '8px' }}>{selectedRink.nom}</h2>
              <p style={{ margin: '4px 0' }}>
                Adresse: 
                <a 
                  href={`https://www.google.com/maps/search/?api=1&query=${selectedRink.address}`}
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: '#007bff' }}
                >
                  <strong> {selectedRink.address}</strong>
                </a>
              </p>
              <p style={{ margin: '4px 0' }}>Statut: <strong>{selectedRink.ouvert == 1 ? 'Ouvert' : 'Fermée'}</strong></p>
              <p style={{ margin: '4px 0' }}>Dernière mise à jour: <strong>{selectedRink.arrondissement.date_maj}</strong></p>
              <p style={{ margin: '4px 0' }}>Condition: <strong>{selectedRink.condition}</strong></p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default RinkMap;
